export const ACCOUNT_TYPES = ['PARENT', 'PLAYER', 'SCOUT'];
export enum ACCOUNT {
    PLAYER = 1,
    SCOUT = 2,
    PARENT = 3,
    CLUB = 4,
}
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const POSITIONS =
    [
        {
            "key": "Goalkeeper",
            "value": 1
        },
        {
            "key": "Center-Back",
            "value": 2
        },
        {
            "key": "Left-Back",
            "value": 3
        },
        {
            "key": "Right-Back",
            "value": 4
        },
        {
            "key": "Defensive Midfield",
            "value": 5
        },
        {
            "key": "Center Midfield",
            "value": 6
        },
        {
            "key": "Attacking Midfield",
            "value": 7
        },
        {
            "key": "Center Forward",
            "value": 8
        },
        {
            "key": "Left Winger",
            "value": 9
        },
        {
            "key": "Right Winger",
            "value": 10
        }
    ];

export enum SEARCH_TYPE {
    PLAYER = 0,
    VIDEO = 1
}